import { Query } from 'types/cms';
import Seo from '@/components/Seo';
import { GetStaticProps } from 'next';
import { GET_PAGE } from '../cms/queries';
import { apolloCon } from '../cms';
import BlockManager from '../components/BlocksManager';

export default function HomePage({ pages }: Query) {
  return (
    <>
      <Seo metadata={pages?.data[0]?.attributes?.meta} />
      <BlockManager
        pageInfo={pages?.data[0]?.attributes}
        blocks={pages?.data[0]?.attributes?.body}
      />
    </>
  );
}

export const getStaticProps: GetStaticProps = async (context) => {
  const { data } = await apolloCon.query({
    query: GET_PAGE,
    variables: {
      donateOptionsFilters2: {
        active: {
          eq: true,
        },
      },
      filters: {
        slug: {
          eq: 'index',
        },
      },
    },
  });

  return {
    props: {
      pages: data.pages,
    },
    revalidate: 10,
  };
};
